import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/nodejs-events",
  "date": "2015-01-27",
  "title": "NODE.JS – EVENTS",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Many objects in Node emit events: a net.Server emits an event each time a peer connects to it, and fs.readStream emits an event when the file is opened. All objects which emit events are instances of events.EventEmitter."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You can access this module by doing: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require("events");
`}</code></pre>
    <h2>{`EVENTS IN THE DOM`}</h2>
    <p>{`The DOM triggers Events, so you can listen for those events.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8623/16191913078_18cb6241c7_o.png",
        "alt": "DOM triggers Events"
      }}></img></p>
    <p>{`Many object in Node emit events`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7405/15759562203_4c1562a5b6_o.png",
        "alt": "Node emit events"
      }}></img></p>
    <h2>{`CUSTOM EVENT EMITTERS`}</h2>
    <p>{`You can write your custom event emitters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var EventEmitter = require('events').EventEmitter;
var logger = new EventEmitter();

logger.on('error', function(message){
    console.log('ERR: ' + message);
});

logger.emit('error', 'Spilled Milk'); // ERR: Spilled Milk
logger.emit('error', 'Eggs Cracked'); // ERR: Eggs Cracked
`}</code></pre>
    <p>{`Error events could be:`}</p>
    <ul>
      <li parentName="ul">{`error`}</li>
      <li parentName="ul">{`warn`}</li>
      <li parentName="ul">{`info`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7340/15759585913_1c832d054b_o.png",
        "alt": "Error events"
      }}></img></p>
    <h2>{`HTTP ECHO SERVER`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`http.createServer(function(request, response){ ... });
`}</code></pre>
    <p>{`This is further explained below:`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7397/16379598345_b06c436698_o.png",
        "alt": "HTTP ECHO SERVER"
      }}></img></p>
    <h2>{`ALTERNATE SYNTAX`}</h2>
    <p>{`Above syntax can be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var server = http.createServer();

server.on('request', function(request, response){ ... });
server.on('close', function(){ ... });
`}</code></pre>
    <p>{`Event `}<strong parentName="p">{`close`}</strong>{` is emitted when the server closes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      